import React from "react";
import "./mouseScroll.css"

const MouseScroll = () => {
    return (
        <div className={"mouse"}/>
    )
};

export default MouseScroll;
