import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import colors from "../../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserHardHat,
    faOilWell,
    faAbacus, faPencilRuler,
} from "@fortawesome/pro-duotone-svg-icons";
import {faGavel} from "@fortawesome/pro-solid-svg-icons";

export const TitleWithIconBox = (props) => {
  return (
    <Col
      xs={10}
      md={4}
      className={"scrollButton"}
      onClick={() => {
        document
          .getElementById(props.scrollTo)
          .scrollIntoView({ behavior: "smooth" });
      }}
    >
      <FontAwesomeIcon
        icon={props.icon}
        color={colors.primary}
        size={"5x"}
        className={"mb-3"}
      />
      <h3 style={{ color: colors.primary }}>{props.title}</h3>
      <p className={"mb-0"}>{props.paragraph}</p>
    </Col>
  );
};

const IndustriesColumns = () => {
  return (
    <Container>
      <Row
        className={
          "pt-5 text-center justify-content-center justify-content-center"
        }
      >
        <TitleWithIconBox
          icon={faUserHardHat}
          title={"Construction"}
          scrollTo={"construction"}
        />
        <TitleWithIconBox
          icon={faOilWell}
          title={"Oil & Gas"}
          scrollTo={"energy"}
        />
        <TitleWithIconBox
          icon={faAbacus}
          title={"Accounting & Finance"}
          scrollTo={"accounting"}
        />
        <TitleWithIconBox
          icon={faGavel}
          title={"Legal"}
          scrollTo={"legal"}
        />
        <TitleWithIconBox
          icon={faPencilRuler}
          title={"Engineering"}
          scrollTo={"engineering"}
        />
      </Row>
    </Container>
  );
};

export default IndustriesColumns;
