const colors = () => {
    return ({
        primary:"#78a1c1",
        primaryLight:"#b889ea",
        primaryDark:"#526e87",
        secondary:"#e3c45c",
        secondaryDark:"#a1874b",
        secondaryLight:"#efdfa7",
        primaryOffset:"#507e86",
        white:"#fff",
        black:"#000",
        gray:{
            almostWhite:"#f8f8f8",
            barely:"#ededed",
            lightest:"#DADADA",
            lighter:"#cccccc",
            light:"#999999",
            medium:"#777777",
            dark:"#555555",
            darker:"#333333",
        }
    })
}

export default colors();
