import BigHero from "../components/heroes/bigHero";
import image from "../img/lance-asper-d9Cs-9I1fPo-unsplash.jpg";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import contingencyPhoto from "../img/edge2edge-media-x21KgBfOd_4-unsplash.jpeg"
import retainedPhoto from "../img/nastuh-abootalebi-eHD8Y1Znfpk-unsplash.jpeg"
import containedPhoto from "../img/iStock-1282596364-pichi.jpg"
import { TitleWithIconBox } from "../components/misc/industriesColRow";

const Services = () => {
  return (
    <div className={"pb-5 mb-5"}>
      <BigHero
        image={image}
        height={"20vh"}
        title={<div className={"text-center"}>Services</div>}
      />
      <Container className={"py-5"}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={8}>
            <p className={"text-center"}>
              At Renegade Resources we offer three distinct service lines to
              ensure our clients with a candidacy search process tailored to
              their specific needs. Below you will find a breakdown of our
              Contingency Search Line, Retained Search Line, and our Contained
              Search Line.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ justifyContent: "space-around" }}>
          <TitleWithIconBox
            title={"Contingency Search Line"}
            paragraph={"Steady Flow of Candidates"}
            scrollTo={"contingency"}
          />

          <TitleWithIconBox
            title={"Retained Search Line"}
            paragraph={"Executive Expedited Recruiting"}
            scrollTo={"retained"}
          />

          <TitleWithIconBox
            title={"Contained Search"}
            paragraph={"Large Groups & Projects"}
            scrollTo={"contained"}
          />
        </Row>
      </Container>
      <ServiceSection
        id={"contingency"}
        title={"Contingency Search Line"}
        paragraph={`Our most popular recruitment service, the Contingency Search Line
        allows our clients to browse an unlimited number of resumes free
        of charge. This contingent fee is premised on the annual base
        salary of a given candidate and shall only be assessed to our
        clients upon satisfactory delivery of a candidate. Our Contingency
        Search Line is ideal for companies who are seeking a steady flow
        of candidates over a long period of time, in specific geographical
       locations.`}
        image={contingencyPhoto}
      />
      <ServiceSection
        id={"retained"}
        title={"Retained Search Line"}
        paragraph={`The Retained Search Line is our most premiere service line at Renegade Resources. 
        Our most exclusive contract ensures our clients receive preferential attention to ensure 
        they find a candidate who  dedicated and invested in the mission of their business. At 
        Renegade we understand the financial burden and strategic detriment to a lengthy 
        candidacy search. Our Retained Search line is intended for our clients seeking 
        executive-level placement, at an expedited rate. Renegades fee for the Retained Search 
        is an upfront charge representing 50% of the target fee, the remainder of the fee shall 
        be satisfied by the client upon successful delivery of a candidate.`}
        image={retainedPhoto}
      />
      <ServiceSection
        id={"contained"}
        title={"Contained Search"}
        paragraph={`Becoming more popular with our larger clients, the Contained Search 
        is a unique blend of our contingency and retained service lines. The Contained 
        Search offers lower rates for searches involving multiple candidates. We also
         wanted to provide our clients with a service intended for  multiple candidate 
         placements on a timeline that can best suit their needs. The Contained Search 
         is the ideal option for clients looking to staff entire office or job sites, 
         as they tend to experience rapid growth.`}
        image={containedPhoto}
      />
    </div>
  );
};

const ServiceSection = ({ title, paragraph, image, id }) => {
  return (
    <Container className={"mt-5"} id={id}>
      <Row className={"pt-5"}>
        <Col xs={12} lg={6}>
          <h2 className={"simple"}>{title}</h2>
          <p>{paragraph}</p>
        </Col>
        <Col>
          <Image
            fluid
            rounded
            alt={"Lots of buildings looked from a low angle"}
            src={image}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
